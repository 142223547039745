import { Link } from "gatsby"
import React from "react"
import Header from "../components/Header"
import SEO from "../components/SEO"

const Page = () => {
  return (
    <>
      <SEO title="Säännöt" />
      <Header />
      <main>
        <h1>Arvonnan säännöt</h1>
        <div className="text-left">
          <ul>
            <li>
              Arvonnan järjestäjä on Lumme Energia Oy, Prikaatinkatu 3A, 50101 Mikkeli, y-tunnus 2839885-8 (myöhemmin
              “järjestäjä”)
            </li>
            <li>
              Arvontaan voivat osallistua kaikki Suomessa pysyvästi asuvat 18 vuotta täyttäneet luonnolliset henkilöt
              pois lukien niiden yhtiöiden työntekijät, jotka ovat olleet tekemisissä tämän kilpailun järjestelyjen
              kanssa.
            </li>
            <li>
              Päiväkohtaiseen arvontaan osallistutaan täyttämällä lomake kuluvan päivän kalenteriluukussa verkkosivulla
              https://joulu.lumme-energia.fi
            </li>
            <li>
              Päiväkohtaisen arvonnan voittaja(t) saa(vat) tiedon voitosta arvonnassa ilmoittamaansa
              sähköpostiosoitteeseen saman päivän aikana.
            </li>
            <li>
              Pääpalkinnon arvontaan osallistutaan kokoamalla palapeli verkkosivuilla https://joulu.lumme-energia.fi.
              24-osaiseen palapeliin saa yhden palan jokaisesta kalenterin luukusta. Kun palapeli on koottu, pääsee
              lähettämään tietonsa lomakkeella. Pääpalkinto arvotaan tietojensa lähettäneiden kesken. Moninkertaiset
              osallistumiset karsitaan pois listasta ennen arvontaa.
            </li>
            <li>Pääpalkinnon arvonnan voittajalle ilmoitetaan henkilökohtaisesti.</li>
            <li>Pääpalkinnon arvonnan suorittaa arvonnan järjestäjä 3.1.2022.</li>
            <li>Palkinnon lunastaminen: Arvonnan voittajille ilmoitetaan henkilökohtaisesti.</li>
            <li>
              Voittajien nimet ja asuinpaikkakunnat voidaan julkaista verkkosivuilla, uutiskirjeissä ja sosiaalisen
              median kanavissa ilman erillistä pyyntöä.
            </li>
            <li>
              Mikäli pääpalkinnon voittajaa ei tavoiteta viikon kuluessa arvonnan suorittamisesta, järjestäjällä on
              oikeus valita tilalle toinen voittaja.
            </li>
            <li>Järjestäjä vastaa palkinnoista maksettavan lakisääteisen arpajaisveron suorittamisesta.</li>
            <li>Järjestäjä pidättää oikeuden muuttaa näitä sääntöjä.</li>
          </ul>
        </div>
        <nav className="bottom-nav">
          <Link to="/">
            <button>Etusivulle</button>
          </Link>
        </nav>
      </main>
    </>
  )
}

export default Page
